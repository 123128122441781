@font-face {
  font-family: "Product Sans";
  src: url(./fonts/SFPRODISPLAYREGULAR.OTF);
  font-weight: 400;
}
@font-face {
  font-family: "Product Sans";
  src: url(./fonts/SFPRODISPLAYMEDIUM.OTF);
  font-weight: 600;
}
@font-face {
  font-family: "Product Sans";
  src: url(./fonts/SFPRODISPLAYBOLD.OTF);
  font-weight: 700;
}
@font-face {
  font-family: "Circular Std";
  src: url(./fonts/CircularStd-Black.otf);
  font-weight: 900;
}
@font-face {
  font-family: "Circular Std";
  src: url(./fonts/CircularStd-Bold.otf);
  font-weight: 700;
}
@font-face {
  font-family: "Circular Std";
  src: url(./fonts/CircularStd-Medium.ttf);
  font-weight: 500;
}
@font-face {
  font-family: "Circular Std";
  src: url(./fonts/CircularStd-Book.otf);
  font-weight: 400;
}
@font-face {
  font-family: "Product Sans";
  src: url(./fonts/Product\ Sans\ Bold.ttf);
  font-weight: 700;
}
@font-face {
  font-family: "Product Sans";
  src: url(./fonts/Product\ Sans\ Regular.ttf);
  font-weight: 400;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hidescrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hidescrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* .custom-scrollbar::-webkit-scrollbar {
  width: 10px;
} */

.custom-scrollbar::-webkit-scrollbar {
  overflow-x: hidden;
  /* width: 20px; */
}

.custom-scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
}

.css-l8zk7d-MuiPaper-root-MuiDialog-paper {
  border-radius: 10px !important;
}

@media screen and (-webkit-min-device-pixel-ratio:0)
and (min-resolution:.001dpcm) {
  .custom-scrollbar::-webkit-scrollbar {
    width: 1px;
  }
}

@media (-webkit-min-device-pixel-ratio:0) {
  .custom-scrollbar::-webkit-scrollbar {
    width: 1px;
  }
  }

  @media screen and(-webkit-min-device-pixel-ratio:0) {
    .custom-scrollbar::-webkit-scrollbar {
      width: 1px;
    }
    }