.link .mainLink {
  text-decoration: none;
  font-size: 16px;
  font-weight: 400;
  font-family: "Product Sans";
  color: #000;
  display: inline-block;
  padding: 26px 0px;
}

.slick-dots li.slick-active button:before {
  color: White !important;
}

.clipBg {
  clip-path: polygon(50% 0%, 100% 0, 100% 90%, 0 100%, 0 0);
}

.boxShadow {
  box-shadow: 0px 5px 50px -20px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 5px 50px -20px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 5px 50px -20px rgba(0, 0, 0, 0.2);
}

.overlayBg {
  position: relative;
  overflow: hidden;
  transform: rotate(30deg);
}

.overlayBg::before {
  content: "";
  position: absolute;
  width: 200%;
  height: 200%;
  top: -50%;
  left: -50%;
  z-index: -1;
  background: url(../../Imgs/header-overlay.svg) 0 0 repeat;
  transform: rotate(-30deg);
}

@media only screen and (max-width: 768px) {
  .slick-dots {
    bottom: 5% !important;
  }
  .header-img {
    width: 100%;
  }
}
@media only screen and (min-width: 769px) {
  .header-img {
    width: 140%;
  }
}

.hoverMenu {
  position: relative;
}

.hoverMenuItem {
  border-top: 5px solid #0085ff;
  position: absolute;
  background-color: #fff;
  top: 70px;
  box-shadow: 0px 8px 12px rgba(5, 20, 65, 0.15);
  display: none;
}

.hoverMenu:hover .hoverMenuItem {
  display: block;
}

.link .subLink {
  text-decoration: none;
  font-size: 16px;
  font-weight: 400;
  font-family: "Product Sans";
  color: #000;
  display: inline-block;
  padding: 10px 20px;
}

.link .subLink:hover {
  background-color: rgb(246, 246, 246);
}

.hoverMenu:hover .mainLink img {
  transform: rotate(180deg);
  transition: 0.6s ease;
}
