

.input_login{
    width: 97%;
    height: 50px;
    margin-top: 5px;
    margin-bottom: 12px;
    padding: 0px 15px 0px 15px ;
    font-size: 14px;
    border: 1px solid #EFF0F7;
    font-weight: bold;
    box-shadow: 0px 2px 6px rgba(19, 18, 66, 0.07);
    border-radius: 10px;
}
.MuiInputBase-root, MuiOutlinedInput-root, MuiInputBase-colorPrimary, MuiInputBase-formControl, css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root{
    width: 100% !important;
    height: 50px;
    /* font-weight: bold; */
    box-shadow: 0px 2px 6px rgba(19, 18, 66, 0.07);  
    background-color: #fff;
    border-radius: 4px !important;
}
/* .MuiOutlinedInput-notchedOutline, css-1d3z3hw-MuiOutlinedInput-notchedOutline{
    height: 55px;
    border: 1px solid ;

} */
/* .MuiFormControl-root, MuiTextField-root css-gp1epq-MuiFormControl-root-MuiTextField-root{
    height: 50px;
     border: 1px solid red;
} */