.field-name{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #A2A3A6;
    margin:"50px"
}
.field-back{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 30px;
    color: #A2A3A6;
}
.bedroom-box{
    width: 63px;
    height: 71px; 
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    margin:0px 20px 0px 0px;
    cursor: pointer;
    background: #EAECF2;
    color: #5D6174;
}

.color-after{
    background: #FF6140;
    color: #FFFFFF;
}

