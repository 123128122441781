.rowSpaceBetween {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin:0px 20px;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
   /* color:#272D4E; */
   font-weight:700;
   font-family:Circular Std;
   font-size:18px;
}

.bruh{
    border: 0px solid transparent;
    padding: 2px 5px;
    text-align: left;
    font-size: 16px;
}