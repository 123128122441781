table {
  border-collapse: collapse;
  width: 100%;
  margin-top: 10px;
}
th,
td {
  border: 2px solid #000;
  padding: 2px 5px;
  text-align: left;
  font-size: 10px;
}
