.select__control {
  min-height: "50px";
}
.univercel_btn_class {
  background: linear-gradient(96.67deg, #0085ff 1.65%, #009fff 96.59%);
  font-weight: 500;
  font-size: 14px;
  padding-top: 13px;
  padding-bottom: 13px;
  text-transform: none !important;
  color: white !important;
  font-family: "Product Sans";
}

.univercel_btn_class:hover {
  box-shadow: -4px 6px 14px rgba(23, 147, 255, 0.3);
}

.App {
  color: black;
}

.paginationButton {
  background: #0a2260 !important;
}
/* button:disabled,
button[disabled]{
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
} */

.paginationButton[disabled],
.paginationButton:disabled {
  background: #cccccc !important;
}

.WelcomeText {
  color: #4e5a85;
  font-weight: 600;
}

.profileIconColor {
  background-color: #0a2260 !important;
}
