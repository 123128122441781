.select__control css-1s2u09g-control {
  height: 55px;
  width: "118px";
  margin-top: 10px;
  margin-bottom: 12px;
  padding-left: 10px;
  font-size: 14px;
  border: 1px solid #eff0f7;
  font-weight: bold;
  box-shadow: 0px 2px 6px rgba(19, 18, 66, 0.07);
  border-radius: 10px;
}
.text_color_linear {
  background: linear-gradient(90.53deg, #0e5cad 3.24%, #4bb2a8 104.54%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* text-fill-color: transparent; */
  background-clip: text;

  font-size: 18px;
  font-weight: 500;
}
/* .css-3zg29u-MuiStack-root{
    width:400px !important ; 
} */
/* .css-1q6at85-MuiInputBase-root-MuiOutlinedInput-root{
    background-color: #D9D9D9 !important;

} */
