.hover_button {
  height: 45px;
  border-radius: 4px;
  padding: 0px 10px 0px 10px;
  width: 100%;
}
.hover_button:hover {
  background: linear-gradient(96.67deg, #0085ff 1.65%, #009fff 96.59%);
  color: aliceblue;
}
.hover_button_icon {
  color: #0f5dae;
}
.hover_button_icon:hover {
  color: aliceblue;
}
.hover_button_iconn {
  fill: #0f5dae;
}
.hover_button:hover .hover_button_iconn {
  fill: aliceblue;
}
.hover_button:hover .hover_button_icon {
  fill: aliceblue;
}
.hover_button:hover .link_name {
  color: aliceblue;
}

.sidebar_option {
  margin-bottom: 10px;
  height: 45px;
  border-radius: 4px;
  padding: 0px 10px 0px 10px;
  width: 95%;
}
.sidebar_option:hover {
  background: linear-gradient(90deg, #0f5dae 0%, #009dcf 100.33%);
  color: aliceblue;
}
.sidebar_option_icon {
  color: #0f5dae;
}
.sidebar_option_icon:hover {
  color: aliceblue;
}
.sidebar_option_iconn {
  fill: #0f5dae;
}
.sidebar_option:hover .sidebar_option_iconn {
  fill: aliceblue;
}
.sidebar_option:hover .sidebar_option_icon {
  fill: aliceblue;
}
.sidebar_option:hover .link_name {
  color: aliceblue;
}
