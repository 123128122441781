.stepper_signup {
  border: 1px solid transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
}
.vertical_line {
  height: 20px;
  margin-left: 18px;
  /* border-left: 2.5px #FFA946 dashed ; */
}
.input_signup {
  width: 100%;
  height: 50px;
  /* margin-top: 10px;
    margin-bottom: 12px; */
  padding-left: 10px;
  font-size: 14px;
  /* border: 1px solid #EFF0F7; */
  font-weight: bold;
  box-shadow: 0px 2px 6px rgba(19, 18, 66, 0.07);
  border-radius: 10px;
}
.input_organization {
  width: 70%;
  height: 50px;
  margin-top: 10px;
  margin-bottom: 12px;
  padding-left: 10px;
  font-size: 14px;
  border: 1px solid #eff0f7;
  font-weight: bold;
  box-shadow: 0px 2px 6px rgba(19, 18, 66, 0.07);
  border-radius: 10px;
}
.input_organization_row {
  width: 100%;
  height: 50px;
  margin-top: 10px;
  margin-bottom: 12px;
  padding-left: 10px;
  font-size: 14px;
  border: 1px solid #eff0f7;
  font-weight: bold;
  box-shadow: 0px 2px 6px rgba(19, 18, 66, 0.07);
  border-radius: 10px;
}
.box_row {
  width: 30%;
}
.orange_hover {
  color: #0085FF;
  margin-left: 10px;
}
.orange_hover:hover {
  cursor: pointer;
}
