table {
  border-collapse: collapse;
  width: 100%;
  margin-top: 10px;
}
.ff {
  font-size: 16px;
  font-family: Product Sans;
  color: #272d4e;
  font-weight: 700;
}
th,
td {
  border: 0px solid #000;
  text-align: left;
  font-size: 10px;
}
