.input label {
  font-size: 16px;
  font-weight: 400;
}

.input input {
  border: none;
  outline: none;
  border-bottom: 1px solid #000;
  margin-left: 5px;
  font-size: 16px;
  font-weight: 400;
}

.formGroup {
  display: block;
  margin-bottom: 5px;
}

.formGroup input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.formGroup label {
  position: relative;
  cursor: pointer;
  font-size: 12px;
  font-weight: 400;
}

.formGroup label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #000;
  padding: 7px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
}

.formGroup input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 3px;
  left: 6px;
  width: 4px;
  height: 8px;
  border: solid #000;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
