.hover_button {
  height: 45px;
  /* border-radius: 4px; */
  padding: 0px 10px 0px 10px;
  width: 100%;
}
.hover_button:hover {
  background: linear-gradient(96.67deg, #0085ff 1.65%, #009fff 96.59%);
  color: aliceblue;
}
.hover_button_icon {
  color: #0f5dae;
}
.hover_button_icon:hover {
  color: aliceblue;
}
.hover_button_iconn {
  fill: #0f5dae;
}
.hover_button:hover .hover_button_iconn {
  fill: aliceblue;
}
.hover_button:hover .hover_button_icon {
  fill: aliceblue;
}
.hover_button:hover .link_name {
  color: aliceblue;
}

.sidebar_option {
  margin-bottom: 10px;
  height: 45px;
  /* border-radius: 4px; */
  padding: 0px 10px 0px 10px;
  width: 95%;
}
.sidebar_option:hover {
  background: #F5F7FF;
  color: aliceblue;
}
.sidebar_option_icon {
  color: #1976D2;
}
.sidebar_option_icon:hover {
  color: #1976D2;
}
.sidebar_option_iconn {
  fill: #1976D2;
}
.sidebar_option:hover .sidebar_option_iconn {
  fill: #1976D2;
}
.sidebar_option:hover .sidebar_option_icon {
  fill: #1976D2;
}
.sidebar_option:hover .link_name {
  color: #1976D2;
}

.lockedImage{
  background-image: url("../../../Imgs/builder-welcome-new.png");
  /* border:1px solid red; */
  width:100%;
  height:250px;
  background-position:center;
  background-repeat: no-repeat;
  background-size: contain;
  margin:auto;
  border-radius:10px;
  text-align: start;
}
