.link a {
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
  font-family: "Product Sans";
  color: #fff;
  display: inline-block;
}

.slick-dots li.slick-active button:before {
  color: White !important;
}

.clipBg {
  clip-path: polygon(50% 0%, 100% 0, 100% 90%, 0 100%, 0 0);
}

.overlayBg {
  position: relative;
  overflow: hidden;
  transform: rotate(30deg);
}

.overlayBg::before {
  content: "";
  position: absolute;
  width: 200%;
  height: 200%;
  top: -50%;
  left: -50%;
  z-index: -1;
  background: url(../../Imgs/header-overlay.svg) 0 0 repeat;
  transform: rotate(-30deg);
}

@media only screen and (max-width: 768px) {
  .slick-dots {
    bottom: 5% !important;
  }
  .header-img {
    width: 100%;
  }
}
@media only screen and (min-width: 900px) {
  .header-img {
    width: 140%;
  }
}
